import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Divider from "@material-ui/core/Divider";
import { partners } from "../assets/constants";


const useStyles = makeStyles((theme) => ({
  frame: {},
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
  },
  cardDetails: {
    width: "40%",
    marginTop: "auto",
    marginBottom: "auto",
  },
  cardContent: {
    height: 150,
    margin: theme.spacing(3),
  },
  members: {
    margin: theme.spacing(3),
  },
  cardMedia: {
    height: 570,
    width: "100%",
    padding: theme.spacing(5),
    display: "flex",
    flexFlow: "column",
  },
  cardImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    flex: "0 1 auto",
  },
  title: {},
  description: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function Partners() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.frame}>
      <Grid container spacing={3} justify="space-around">
        {partners.map((partner, idx) => {
          const { img, title, description, website } = partner;
          return (
            <Grid key={"partner-" + idx} item md={3} style={{ width: "100%" }}>
              <Card className={classes.card}>
                <CardActionArea href={website} target="_blank">
                  <div className={classes.cardMedia}>
                    <div style={{ flex: "1 1 auto" }}></div>
                    <img
                      className={classes.cardImage}
                      src={img}
                      alt="Kessler Solutions"
                    />
                    <div style={{ flex: "1 1 auto" }}></div>
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      className={classes.title}
                      component="h2"
                      variant="h5"
                    >
                      {title}
                    </Typography>
                    <Divider />
                    <Typography
                      gutterBottom
                      className={classes.description}
                      component="h2"
                      variant="subtitle1"
                    >
                      {description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardContent className={classes.members}>
                  {partner.members.map((member) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Avatar src={member.img} className={classes.large} />
                        </Grid>
                        <Grid item>
                          <Typography>{member.name}</Typography>
                          <Typography>{member.email}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
