import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import FA from "react-fontawesome";
import Link from "@material-ui/core/Link"
import {NavLink as BaseNavLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    }, activeLink: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
        textDecoration: "none",
        fontSize: theme.typography.h6.fontSize,
        "&:hover": {
            color: theme.palette.success.light,
        },
    }, link: {
        color: theme.palette.grey[400],
        marginRight: theme.spacing(2),
        textDecoration: "none",
        fontSize: theme.typography.h6.fontSize,
        "&:hover": {
            color: theme.palette.success.light,
        },
    },
}));

const NavLink = React.forwardRef(({activeClassName, activeStyle, ...props}, ref) => {
    const classes = useStyles();
    const {t} = useTranslation("common");
    let {href, label, iconName} = props;
    return (<BaseNavLink
        ref={ref}
        {...props}
        className={({isActive}) => isActive ? classes.activeLink : classes.link}
    >{iconName && <FA name={iconName}/>}
        &nbsp;
        {label?label:""}</BaseNavLink>);
});

export default NavLink;
