import React, { useContext } from "react";
import { Context } from "../Context";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  dialog: {},
}));

export default function Account(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open, close } = props;
  const { rootState } = useContext(Context);
  const { token } = rootState;

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [passwords, setPasswords] = React.useState({ old: "", new: "" });

  function handleChange(e) {
    setPasswords({
      ...passwords,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  }

  function handleClose() {
    close();
  }

  function handleSubmit() {
    axios
      .post(
        "https://sparepartassist.f2.htw-berlin.de/auth/changepassword.php",
        {
          oldpassword: passwords.old,
          password: passwords.new,
        },
        config
      )
      .then(function (res) {
        const { data } = res;
        if (data.success) {
          enqueueSnackbar("Passwort geändert!", { variant: "success" });
          close();
        } else {
          enqueueSnackbar(data.message, { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">Password ändern</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="old"
            label="Altes Passwort"
            type="password"
            name="old"
            autoFocus
            value={passwords.old}
            autoComplete="current-password"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="new"
            label="Neues Passwort"
            type="password"
            id="new"
            value={passwords.new}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary" type="submit" autoFocus>
          absenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
