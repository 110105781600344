import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Divider from "@material-ui/core/Divider";
import neumann from "../assets/images/neumann.png";
import htw from "../assets/images/htw.jpg";
import FA from "react-fontawesome";

const useStyles = makeStyles((theme) => ({
  frame: {
    marginBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
  },
  cardDetails: {
    width: "40%",
    marginTop: "auto",
    marginBottom: "auto",
  },
  cardContent: {
    margin: theme.spacing(3),
  },
  cardMedia: {
    maxheight: 570,
    width: "100%",
    padding: theme.spacing(10),
    display: "flex",
    flexFlow: "column",
  },
  cardImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    flex: "0 1 auto",
  },
  textContainer: {
    height: "100%",
    padding: theme.spacing(6),
    color: theme.palette.common.white,
    background: "radial-gradient(circle, rgba(184,210,121,1) 0%, rgba(119,185,0,1) 100%)",
    //backgroundColor: theme.palette.background.main,
  },
  title: {
    fontSize: "1.4rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.4rem",
    },
  },
  description: {
    marginTop: theme.spacing(3),
    fontSize: "0.85rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem",
    },
  },
}));

const contacts = [
  {
    img: neumann,
    title: "Prof. Dr.-Ing. Frank Neumann",
    description: (
      <div>
        Hochschule für Technik und Wirtschaft Berlin
        <br />
        Fachbereich 2 – Ingenieurswissenschaften
        <br />
        Wilhelminenhofstraße 75A
        <br />
        12459 Berlin
        <br />
        <FA name="envelope" />
        &nbsp;Frank.Neumann@HTW-Berlin.de
        <br />
        <FA name="phone" />
        &nbsp;+49 30 5019-3295
      </div>
    ),
    website: "https://htw-berlin.de/hochschule/personen/person/?eid=9685",
  },
];

export default function Impressum() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  //States

  //Handlers

  return (
    <div className={classes.frame}>
      <Grid container spacing={3} justify="space-around">
        <Grid item xs={12}>
          <Link href="https://htw-berlin.de" color="inherit" underline="none">
            <Paper elevation={3}>
              <Grid container alignItems="stretch">
                <Grid item xs={12} lg={5}>
                  <img src={htw} alt="workflow" className={classes.cardMedia} />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid
                    container
                    className={classes.textContainer}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        gutterBottom
                        className={classes.title}
                      >
                        Hochschule für Technik und Wirtschaft Berlin
                      </Typography>
                      <Divider />
                      <Typography className={classes.description}>
                        Prof. Dr.-Ing. Frank Neumann
                        <br />
                        Hochschule für Technik und Wirtschaft Berlin
                        <br />
                        Fachbereich 2 – Ingenieurswissenschaften
                        <br />
                        Wilhelminenhofstraße 75A, 12459 Berlin
                        <br />
                        www.htw-berlin.de
                        <br />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
