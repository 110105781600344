import React, { useContext } from "react";
import { Context } from "../Context";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  dialog: {},
}));

export default function Register(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open, close } = props;
  const { rootState } = useContext(Context);
  const { token } = rootState;

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = React.useState({ name: "", email: "", password: "" });

  function handleChange(e) {
    setUser({ ...user, [e.currentTarget.name]: e.currentTarget.value });
  }

  function handleClose() {
    close();
  }

  function handleSubmit() {
    axios
      .post(
        "https://sparepartassist.f2.htw-berlin.de/auth/register.php",
        {
          name: user.name,
          email: user.email,
          password: user.password,
        },
        config
      )
      .then(function (response) {
        if (response) {
          enqueueSnackbar("Benutzer hinzugefügt", { variant: "success" });
          console.log("success", response);
          close();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">
        Neuen Benutzer anlegen
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={user.name}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={user.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            value={user.password}
            autoComplete="current-password"
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary" type="submit" autoFocus>
          absenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
