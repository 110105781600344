import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Divider from "@material-ui/core/Divider";
import workflow from "../assets/images/workflow.png";
import TimeLine from "./TimeLine";
import firstlogo from "../assets/images/spa_logo.png";
import logo from "../assets/images/spa_logo_grey_text_white.png";

const useStyles = makeStyles((theme) => ({
    textContainer: {
        height: "100%",
        padding: theme.spacing(6),
        color: theme.palette.common.white,
        //backgroundColor: theme.palette.background.main,
        background: "linear-gradient(0deg, rgba(52,157,154,1) 0%, rgba(131,201,126,1) 50%)",
},
    cardContent: {
        margin: theme.spacing(6),
    },
    title: {
        fontSize: "2.0rem",
        ...theme.typography.primary,
        [theme.breakpoints.up("sm")]: {
            fontSize: "2.8rem",
        },
    },
    workflow: {
        height: "100%",
        width: "100%",
        //opacity: 0.8,
        //filter: "grayscale(0.5) brightness(0.9)"
    },
    bannerLogo: {
        height: "6rem",
        verticalAlign: "middle"
    },
    overlay: {
        /*position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        color: "#FFF",
        display: "block",
        background: "rgba(0, 0, 0, .6)",*/
    },
    sectionTitle: {
        color: theme.palette.text.hint,
        marginBottom: theme.spacing(4),
    },
    description: {
        fontWeight: "700",
        marginTop: theme.spacing(3),
        fontSize: "1.0rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.4rem",
        },
    },
    newsbox: {
        maxHeight: 300,
        overflowY: "auto",
    },
}));

export default function Home(props) {
    const {admin} = props;
    const classes = useStyles();
    const {t} = useTranslation("common");

    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Paper elevation={3}>
                    <Grid container alignItems="stretch">

                        <Grid item xs={12} lg={7} >
                            <img
                                src={workflow}
                                alt="workflow"
                                className={classes.workflow}
                            />
                            <div className={classes.overlay} />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Grid
                                container
                                className={classes.textContainer}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Typography
                                        gutterBottom
                                        className={classes.title}
                                        component="h2"
                                        variant="h3"
                                    >
                                        <img src={logo} alt={"SparePartAssist"}/>
                                    </Typography>
                                    <Divider/>
                                    <div className={classes.description}>
                                        {`Mobiles und AR-basiertes Assistenzsystem für Ersatzteilsuche und -montage unter Nutzung von 3D-Objektrekonstruktion und geometrischer Ähnlichkeitssuche.`}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    key="tl-title"
                    className={classes.sectionTitle}
                    align="center"
                    variant="h4"
                >
                    Zeitplan
                </Typography>
                <TimeLine admin={admin}/>
            </Grid>
        </Grid>
    );
}

/*      <Grid item xs={12}>
        <Paper elevation={3}>
          <Grid container alignItems="stretch">
            <Grid item lg={3}>
              <Center>
                <Typography
                  key="news-title"
                  className={classes.sectionTitle}
                  align="center"
                  variant="h4"
                >
                  Neuigkeiten:{" "}
                </Typography>
              </Center>
            </Grid>
            <Grid item className={classes.newsbox} lg={9}>
              <News admin={admin} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>*/
