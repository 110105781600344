import i18next from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import common_de from "./assets/translations/de.json";
import common_en from "./assets/translations/en.json";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

/*i18n
.use(Backend)
.use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["translation", "common"],
    defaultNS: 'translation',
    fallbackLng: 'de',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
      }
  });*/

i18next.init({
  defaultNS: "common",
  debug: false,
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "de", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
  },
});

export default i18next;
