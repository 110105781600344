import React, {useContext} from "react";
import {Context} from "../Context";
import moment from "moment-timezone";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, MemoryRouter as Router} from 'react-router-dom';
import Blogpost from "./Blogpost";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import FA from "react-fontawesome";
import {Grid, TextField} from "@material-ui/core";
import {Editor} from "@tinymce/tinymce-react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

const BASE_URL = "https://sparepartassist.f2.htw-berlin.de/";

const useStyles = makeStyles((theme) => ({
    title: {fontWeight: "bold"},
    card: {boxShadow: theme.shadows[3]},

    news: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    editor: {
        padding: theme.spacing(4),
    },
}));

const intVals = ["id", "date"];

export default function News(props) {
    const {admin} = props;
    const {rootState, logoutUser, loginUser, isLoggedIn} = useContext(Context);
    const {isAuth, theUser, showLogin, token} = rootState;
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
        },
    };
    const classes = useStyles();
    const {t} = useTranslation("common");
    const {enqueueSnackbar} = useSnackbar();

    const initItems = () => {
        axios
            .get("https://sparepartassist.f2.htw-berlin.de/blogposts.php")
            .then((res) => {
                setSelectedItems(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [items, setSelectedItems] = React.useState([]);

    const initItem = {
        date: moment().valueOf().toString(),
        author: theUser ? theUser.name : "",
        title: "",
        description: "",
        previewImage: BASE_URL + "images/logo512.png",
        html: "",
    };

    const [selectedItem, setSelectedItem] = React.useState(initItem);

    //console.log(selectedItem);

    React.useEffect(initItems, []);
    React.useEffect(() => {
        if (isAuth) setSelectedItem({...selectedItem, author: theUser.name});
    }, [isAuth]);

    function handleAdd(e) {
        setSelectedItem(initItem);
    }

    function handleEdit(e) {
        setSelectedItem(items[parseInt(e.currentTarget.name)]);
    }

    function handleDelete(e) {
        console.log(e.currentTarget);
        const idx = parseInt(e.currentTarget.name);
        if (idx < items.length) {
            if (!window.confirm("Wirklich löschen?")) return;
            deleteItemDB(items[idx]);
            setSelectedItem(initItem);
        }
    }

    function addItemDB(item) {
        const fields = Object.keys(item);
        const itemVals = fields
            .map((key) => {
                let value = item[key];
                if (!intVals.includes(key)) value = "'" + value + "'";
                return value;
            })
            .join(",");
        const sql = `INSERT INTO blogposts (${fields.join(
                ","
        )})
                     VALUES (${itemVals})`;
        console.log(config);
        axios
            .post(
                "https://sparepartassist.f2.htw-berlin.de/admin.php",
                {
                    sql: sql,
                },
                config
            )
            .then(function (res) {
                const {data} = res;
                if (data.success) enqueueSnackbar("hinzugefügt", {variant: "success"});
                else {
                    enqueueSnackbar(data.message, {variant: "error"});
                    if (data.message === "UNAUTHORIZED") logoutUser();
                }
                initItems();
            })
            .catch((error) => {
                initItems();
                console.log(error);
            });
    }

    function editItemDB(item) {
        const fields = Object.keys(item);
        const updateVals = fields
            .map((key) => {
                let updateVal = item[key];
                if (!intVals.includes(key)) updateVal = "'" + updateVal + "'";
                updateVal = key + "=" + updateVal;
                return updateVal;
            })
            .join(",");
        axios
            .post(
                "https://sparepartassist.f2.htw-berlin.de/admin.php",
                {
                    sql: `UPDATE blogposts
                          SET ${updateVals}
                          WHERE id = ${item.id}`,
                },
                config
            )
            .then(function (res) {
                const {data} = res;
                if (data.success) enqueueSnackbar("geändert", {variant: "success"});
                else {
                    enqueueSnackbar(data.message, {variant: "error"});
                    if (data.message === "UNAUTHORIZED") logoutUser();
                }
                initItems();
            })
            .catch((error) => {
                initItems();
                console.log(error);
            });
    }

    function deleteItemDB(item) {
        axios
            .post(
                "https://sparepartassist.f2.htw-berlin.de/admin.php",
                {
                    sql: `DELETE
                          FROM blogposts
                          WHERE id = ${item.id}`,
                },
                config
            )
            .then(function (response) {
                if (response.data) {
                    enqueueSnackbar("gelöscht", {variant: "success"});
                    console.log("success", response);
                }
                initItems();
            })
            .catch((error) => {
                initItems();
                console.log(error);
            });
    }

    function handleChange(e) {
        setSelectedItem({...selectedItem, [e.target.name]: e.target.value});
    }

    function handleDateChange(e) {
        setSelectedItem({
            ...selectedItem,
            [e.target.name]: moment(e.target.value).valueOf(),
        });
    }

    function handleEditorChange(content) {
        setSelectedItem({...selectedItem, html: content});
    }

    function handleSubmit() {
        if (selectedItem.id) {
            editItemDB(selectedItem);
        } else {
            addItemDB(selectedItem);
        }
    }

    function handlePrevieChange(e) {
        const file = e.target.files[0];
        console.log(file);
        if (!file) {
            console.log("path invalid");
            return;
        }

        uploadHandler(
            file,
            (path) => {
                enqueueSnackbar(path, {variant: "success"});
                setSelectedItem({...selectedItem, previewImage: BASE_URL + path});
            },
            (error) => {
                enqueueSnackbar(error, {variant: "error"});
                console.log(error);
            }
        );
    }

    function uploadHandler(file, success, failure) {
        let data = new FormData();
        data.append("file", file, file.name);

        axios
            .post(`https://sparepartassist.f2.htw-berlin.de/upload.php`, data, config)
            .then(function (res) {
                console.log(res);
                const {data} = res;
                if (data.success) success(res.data.message);
                else {
                    failure("HTTP Error: " + data.message);
                    if (data.message === "UNAUTHORIZED") logoutUser();
                }
            })
            .catch(function (err) {
                failure("HTTP Error: " + err.message);
            });
    }

    return (
        <div className={classes.frame}>
            <Grid container spacing={3}>
                {admin && token && (
                    <>
                        <Grid item xs={10} key={"news-preview"}>
                            <Typography variant="h4">Vorschau: </Typography>
                        </Grid>
                        <Grid item xs={2} key={"news-add-btn"}>
                            <Typography align="right">
                                <IconButton aria-label="add" onClick={handleAdd}>
                                    <FA name="plus"/>
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} key={"blogpost-edit"}>
                            <Blogpost item={selectedItem}/>
                        </Grid>
                        <Grid item xs={12} key={"blogpost"}>
                            <Paper className={classes.editor}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="date"
                                            label="Datum"
                                            type="date"
                                            value={moment(parseInt(selectedItem.date)).format(
                                                "YYYY-MM-DD"
                                            )}
                                            onChange={handleDateChange}
                                            className={classes.select}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="author"
                                            label="Author"
                                            value={selectedItem.author}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="title"
                                            label="Titel"
                                            autoFocus
                                            value={selectedItem.title}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="description"
                                            label="Text"
                                            multiline
                                            rows={4}
                                            value={selectedItem.description}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 600}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" component="label">
                                            Upload File
                                            <input type="file" hidden onChange={handlePrevieChange}/>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Editor
                                            apiKey="msjyyfrkm6tdqup8tdkbdawwuoh4jb8i7gmxn4d4l12a9klc"
                                            value={selectedItem.html}
                                            init={{
                                                min_height: 1000,
                                                menubar: true,
                                                plugins: [
                                                    "advlist autolink lists link image",
                                                    "charmap print preview anchor help",
                                                    "searchreplace visualblocks code",
                                                    "insertdatetime media table paste wordcount",
                                                    "image imagetools",
                                                ],
                                                toolbar:
                                                    "undo redo | formatselect | bold italic | \
                                      alignleft aligncenter alignright | \
                                      bullist numlist outdent indent | link | image imagetools | removeformat | code | help",
                                                image_title: true,
                                                automatic_uploads: true,
                                                file_picker_types: "file image media",
                                                document_base_url: BASE_URL,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                convert_urls: true,
                                                images_upload_handler: function (
                                                    blobInfo,
                                                    success,
                                                    failure
                                                ) {
                                                    uploadHandler(blobInfo.blob(), success, failure);
                                                },
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography align="right">
                                            <Button
                                                variant="contained"
                                                onClick={handleSubmit}
                                                color="primary"
                                                autoFocus
                                            >
                                                absenden
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                )}
                {items.map((item, idx) => {
                    return (
                        <>
                            <Grid item xs={12} key={`news-${idx}`}>
                                <Link
                                    key={`news-${idx}-link`}
                                    to={`/news/${item.id}`}
                                    underline="none"
                                    color="inherit"
                                    component={RouterLink}
                                >
                                    <Blogpost item={item}/>
                                </Link>
                            </Grid>
                            {admin && (
                                <Grid item xs={12} key={`news-${idx}-edit`}>
                                    <IconButton
                                        aria-label="delete"
                                        name={idx}
                                        onClick={handleDelete}
                                    >
                                        <FA name="trash"/>
                                    </IconButton>
                                    <IconButton aria-label="edit" name={idx} onClick={handleEdit}>
                                        <FA name="edit"/>
                                    </IconButton>
                                </Grid>
                            )}
                        </>
                    );
                })}
            </Grid>
        </div>
    );
}
