import React, { useContext } from "react";
import { Context } from "../Context";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  dialog: {},
}));

export default function SignIn(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = props;
  const { rootState, logoutUser, loginUser, isLoggedIn } = useContext(Context);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = React.useState("");
  const [pw, setPW] = React.useState("");

  function handleUserChange(e) {
    setUser(e.currentTarget.value);
  }

  function handlePWChange(e) {
    setPW(e.currentTarget.value);
  }

  function handleSubmit() {
    loginUser({
      email: user,
      password: pw,
    })
      .then((data) => {
        if (data.success) {
          localStorage.setItem("loginToken", data.token);
        } else {
          enqueueSnackbar("Error: " + data.message, { variant: "error" });
        }
        console.log(data);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">Authentifizierung</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={user}
            onChange={handleUserChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={pw}
            autoComplete="current-password"
            onChange={handlePWChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" type="submit" autoFocus>
          anmelden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
