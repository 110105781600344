import React, { useContext } from "react";
import { Context } from "../Context";
import moment from "moment-timezone";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AdminDialog from "./Dialog";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSnackbar } from "notistack";
import FA from "react-fontawesome";

const useStyles = makeStyles((theme) => ({
  frame: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },

  news: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
  },
  newsDate: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[3],
  },
  tlTitle: {
    fontWeight: 550,
    fontSize: theme.typography.subtitle1.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  tlText: {
    fontSize: theme.typography.subtitle2.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
  seperator: {
    minHeight: 150,
  },
  done: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  doing: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  coming: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
  finish: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.light,
  },
}));

const intVals = ["id", "date"];

export default function TimeLine(props) {
  const { admin } = props;
  const classes = useStyles();
  const { rootState } = useContext(Context);
  const { token } = rootState;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  const initTimeLine = () => {
    axios
      .get("https://sparepartassist.f2.htw-berlin.de/timeline.php")
      .then((res) => {
        setSelectedItems(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [items, setSelectedItems] = React.useState([]);

  const initItem = {
    date: moment().valueOf().toString(),
    status: "done",
    title: "",
    description: "",
  };

  const [selectedItem, setSelectedItem] = React.useState(initItem);
  const [adOpen, setADopen] = React.useState(false);
  const [adContext, setADContext] = React.useState("add");
  const [adminCallback, setAdminCallback] = React.useState(null);

  React.useEffect(initTimeLine, []);
  React.useEffect(() => setSelectedItem(initItem), [items]);

  function Status(props) {
    const { status } = props;
    switch (status) {
      case "done":
        return <FA name="check" size="lg" />;
      case "doing":
        return <FA name="hammer" size="lg" />;
      case "coming":
        return <FA name="hourglass" size="lg" />;
      case "finish":
        return <FA name="flag-checkered" size="2x" />;
      default:
        return <FA name="check" size="lg" />;
    }
  }

  function handleAdd(e) {
    setAdminCallback(() => addItemDB);
    setSelectedItem(initItem);
    setADContext("add");
    setADopen(true);
  }

  function handleEdit(e) {
    setSelectedItem(items[parseInt(e.currentTarget.name)]);
    setAdminCallback(() => editItemDB);
    setADContext("edit");
    setADopen(true);
  }

  function handleDelete(e) {
    console.log(e.currentTarget);
    setSelectedItem(items[parseInt(e.currentTarget.name)]);
    setAdminCallback(() => deleteItemDB);
    setADContext("delete");
    setADopen(true);
  }

  function addItemDB(data) {
    const { item } = data;
    const fields = Object.keys(item);
    const itemVals = fields
      .map((key) => {
        let value = item[key];
        if (!intVals.includes(key)) value = "'" + value + "'";
        return value;
      })
      .join(",");
    axios
      .post(
        "https://sparepartassist.f2.htw-berlin.de/admin.php",
        {
          sql: `INSERT INTO timeline (${fields.join(
            ","
          )}) VALUES (${itemVals})`,
        },
        config
      )
      .then(function (response) {
        if (response) console.log("success", response);
        initTimeLine();
      })
      .catch((error) => {
        initTimeLine();
        console.log(error);
      });
  }

  function editItemDB(data) {
    console.log(data);
    const { item } = data;
    const fields = Object.keys(item);
    const updateVals = fields
      .map((key) => {
        let updateVal = item[key];
        if (!intVals.includes(key)) updateVal = "'" + updateVal + "'";
        updateVal = key + "=" + updateVal;
        return updateVal;
      })
      .join(",");
    axios
      .post(
        "https://sparepartassist.f2.htw-berlin.de/admin.php",
        {
          sql: `UPDATE timeline SET ${updateVals} WHERE id = ${item.id}`,
        },
        config
      )
      .then(function (response) {
        if (response) console.log("success", response);
        initTimeLine();
      })
      .catch((error) => {
        initTimeLine();
        console.log(error);
      });
  }

  function deleteItemDB(data) {
    const { item } = data;
    axios
      .post(
        "https://sparepartassist.f2.htw-berlin.de/admin.php",
        {
          sql: `DELETE FROM timeline WHERE id = ${item.id}`,
        },
        config
      )
      .then(function (response) {
        if (response.data) console.log("success", response);
        initTimeLine();
      })
      .catch((error) => {
        initTimeLine();
        console.log(error);
      });
  }

  function closeAdminDialog() {
    setADopen(false);
  }

  return (
    <div className={classes.frame}>
      {admin && (
        <Typography align="center">
          <IconButton aria-label="add item" onClick={handleAdd}>
            <FA name="plus" />
          </IconButton>
        </Typography>
      )}
      <Timeline align="alternate">
        {items.map((el, idx) => (
          <TimelineItem key={"tl-item-" + idx}>
            <TimelineOppositeContent key={"tl-opposite-" + idx}>
              <div
                style={{
                  display: "flex",
                  flexDirection: `${(idx + 3) % 2 ? "row-reverse" : "row"}`,
                }}
              >
                <Paper className={classes.newsDate}>
                  <Typography variant="subtitle2">
                    {moment(parseInt(el.date)).format("DD.MM.YYYY")}
                  </Typography>
                </Paper>
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator
              key={"tl-seperator-" + idx}
              className={classes.seperator}
            >
              <TimelineDot className={classes[el.status]}>
                <Status status={el.status} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent key={"tl-contentent-" + idx}>
              <div
                style={{
                  display: "flex",
                  flexDirection: `${(idx + 2) % 2 ? "row-reverse" : "row"}`,
                }}
              >
                <Paper className={classes.news}>
                  <Typography className={classes.tlTitle} component="h1">
                    {el.title}
                  </Typography>
                  <Typography className={classes.tlText}>
                    {el.description}
                  </Typography>
                </Paper>
                {admin && (
                  <>
                    <IconButton
                      aria-label="delete"
                      name={idx}
                      onClick={handleDelete}
                    >
                      <FA name="trash" />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      name={idx}
                      onClick={handleEdit}
                    >
                      <FA name="edit" />
                    </IconButton>
                  </>
                )}
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      {admin && (
        <>
          <Typography align="center">
            <IconButton aria-label="add item" onClick={handleAdd}>
              <FA name="plus" />
            </IconButton>
          </Typography>
          <AdminDialog
            open={adOpen}
            context={adContext}
            setClose={closeAdminDialog}
            item={selectedItem}
            callback={adminCallback}
          />
        </>
      )}
    </div>
  );
}
