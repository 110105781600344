import React from "react";
import moment from "moment-timezone";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  blogTitle: {
    fontWeight: 550,
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  blogpostpaper: {
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
  },
}));

export default function Blogpost(props) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { item } = props;

  return (
    <Paper className={classes.blogpostpaper}>
      <Grid container spacing={2} alignItems="stretch" justify="space-between">
        <Grid item xs={12} md={7}>
          <div style={{ position: "relative", height: "100%" }}>
            <Typography
              component="h5"
              className={classes.blogTitle}
              align="left"
            >
              {item.title}
            </Typography>
            <Typography gutterBottom align="left" variant="subtitle1">
              {moment(parseInt(item.date)).format("DD.MM.YYYY")}
              ,&nbsp;
              {item.author}
            </Typography>
            <Typography
              style={{ paddingBottom: 36 }}
              align="left"
              variant="subtitle2"
            >
              {item.description}
            </Typography>
            <Typography
              color="primary"
              align="left"
              style={{ position: "absolute", bottom: 0, left: 0 }}
            >
              {"weiter lesen..."}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div
            style={{
              display: "table",
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                textAlign: "right",
              }}
            >
              <img
                src={item.previewImage}
                alt="loading..."
                style={{
                  height: 192,
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>{" "}
    </Paper>
  );
}
