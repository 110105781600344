import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { partners } from "../assets/constants";

const useStyles = makeStyles((theme) => ({
  frame: {
    display: "flex",
    flex: "1 1 auto",
    height: "100%",
    flexFlow: "column",
  },
  content: {
    ...theme.typography.primary,
    marginTop: "auto",
    //marginBottom: "auto",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.background.third,
    //boxShadow: theme.shadows[2],
  },
  info: {
    padding: theme.spacing(4),
    //color: theme.palette.grey[300],
    //backgroundColor: theme.palette.background.third,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  function preventDefault(e) {
    e.preventDefault();
  }

  return (
    <div className={classes.frame}>
      <div className={classes.content}>
        <Typography variant="h6" align="center">
          <Link href="/impressum" target="_blank" color="inherit">
            Impressum
          </Link>{" "}
          /{" "}
          <Link href="https://htw-berlin.de/datenschutz/" target="_blank" color="inherit">
            Datenschutz
          </Link>
        </Typography>
      </div>
      <Divider />
      <div className={classes.info}>
        <Grid container spacing={4} justify="space-between">
          <Grid item>
            <Typography noWrap variant="h6">
              Weitere Informationen:
            </Typography>
            <Typography>
              <Link
                color="inherit"
                href="https://www.interaktive-technologien.de/projekte/sparepartassist"
                target="_blank"
              >
                Projektseite BMBF
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography noWrap variant="h6">
              Partner:
            </Typography>
            {partners.map((partner) => {
              return (
                <Typography>
                  <Link color="inherit" href={partner.website}>
                    {partner.title}
                  </Link>
                </Typography>
              );
            })}
          </Grid>
          <Grid item>
            <Typography noWrap variant="h6">
              Projekträger:
            </Typography>
            <Typography>
              <Link
                color="inherit"
                href="https://vdivde-it.de/de"
                target="_blank"
              >
                VDE/VDI-IT
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography noWrap variant="h6">
              Förderer:
            </Typography>
            <Typography>
              <Link
                color="inherit"
                href="https://www.bmbf.de/"
                target="_blank"
              >
                BMBF
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
