import cadenas from "./images/cadenas.png";
import gfai from "./images/gfai.png";
import htw from "./images/htw.jpg";
import kessler from "./images/kessler.png";
import neumann from "./images/neumann.png";
import bookhahn from "./images/bookhahn.png";
import toplak from "./images/toplak.jpg";
import schwartz from "./images/schwartz.jpg";
import brechtel from "./images/brechtel.jpg";
import keßler from "./images/keßler.png";
import muehlberg from "./images/mühlberg.png";
import goumas from "./images/goumas.png";

const partners = [
  {
    img: cadenas,
    title: "Cadenas",
    description:
      "CADENAS Konstruktions-, Softwareentwicklungs und Vertriebs GmbH",
    website: "https://cadenas.de/",
    members: [
      { name: "Frank Epple", img: "", role: "", email: "f.epple@cadenas.de" },
      {
        name: "Daniel Toplak",
        img: toplak,
        role: "",
        email: "d.toplak@cadenas.de",
      },
      { name: "Florian Voit", img: "", role: "", email: "f.voit@cadenas.de" },
      {
        name: "Heiko Schwartz",
        img: schwartz,
        role: "",
        email: "h.schwartz@cadenas.de",
      },
      {
        name: "Andreas Brechtel",
        img: brechtel,
        role: "",
        email: "a.brechtel@cadenas.de",
      },
    ],
  },
  {
    img: gfai,
    title: "GfaI",
    description: "GFaI Gesellschaft zur Förderung angewandter Informatik e. V.",
    website: "https://gfai.de/",
    members: [
      { name: "Tara Lorenz", img: "", role: "", email: "lorenz@gfai.de" },
    ],
  },
  {
    img: htw,
    title: "HTW Berlin",
    description: "Hochschule für Technik und Wirtschaft Berlin",
    website: "https://htw-berlin.de/",
    members: [
      {
        name: "Prof. Dr.-Ing. Frank Neumann",
        img: neumann,
        role: "",
        email: "frank.neumann@htw-berlin.de",
      },
      {
        name: "Marian Bookhahn",
        img: bookhahn,
        role: "",
        email: "bookhahn@htw-berlin.de",
      },
    ],
  },
  {
    img: kessler,
    title: "Keßler Solutions",
    description: "Keßler Real Estate Solutions GmbH",
    website: "https://kesslersolutions.de/",
    members: [
      {
        name: "André Keßler",
        img: keßler,
        role: "",
        email: "",
      },
      {
        name: "Marcus Mühlberg",
        img: muehlberg,
        role: "",
        email: "mmuehlberg@kesslersolutions.de",
      },
      {
        name: "Dimitrios Goumas",
        img: goumas,
        role: "",
        email: "dgoumas@kesslersolutions.de",
      },
    ],
  },
];

export { partners };
