import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FA from "react-fontawesome";

const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: 16,
    marginBottom: 8,
  },
  done: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  doing: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  coming: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
  finish: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.success.light,
  },
}));

const states = ["done", "doing", "coming", "finish"];

function Status(props) {
  const { status } = props;
  switch (status) {
    case "done":
      return <FA name="check" size="lg" />;
    case "doing":
      return <FA name="hammer" size="lg" />;
    case "coming":
      return <FA name="hourglass" size="lg" />;
    case "finish":
      return <FA name="flag-checkered" size="2x" />;
    default:
      return <FA name="check" size="lg" />;
  }
}

export default function Editor(props) {
  const classes = useStyles();
  const { item, callback } = props;

  function handleChange(e) {
    callback({ key: e.target.name, val: e.target.value });
  }

  function handleDateChange(e) {
    callback({ key: e.target.name, val: moment(e.target.value).valueOf() });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="date"
          label="Datum"
          type="date"
          value={moment(parseInt(item.date)).format("YYYY-MM-DD")}
          onChange={handleDateChange}
          className={classes.select}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="status"
          label="Status"
          value={item.status}
          onChange={handleChange}
          select
          className={classes.select}
        >
          {states.map((state) => (
            <MenuItem value={state}>
              <Status status={state} />
              &nbsp;{state}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Titel"
          name="title"
          autoFocus
          value={item.title}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="description"
          label="Text"
          multiline
          rows={4}
          value={item.description}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
