import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
import {I18nextProvider} from 'react-i18next';
import i18next from "./i18n";
import {BrowserRouter} from "react-router-dom";

render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
//registerServiceWorker();

