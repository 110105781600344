import React, { createContext, Component } from "react";
import axios from "axios";
export const Context = createContext();

// Define the base URL
const Axios = axios.create({
  baseURL: "https://sparepartassist.f2.htw-berlin.de/auth/",
});

class ContextProvider extends Component {
  constructor() {
    super();
    this.isLoggedIn();
  }

  // Root State
  state = {
    showLogin: true,
    isAuth: false,
    theUser: null,
    token: null,
    authIsLoaded: false,
  };

  // Toggle between Login & Signup page
  toggleNav = () => {
    const showLogin = !this.state.showLogin;
    this.setState({
      ...this.state,
      showLogin,
    });
  };

  // On Click the Log out button
  logoutUser = () => {
    localStorage.removeItem("loginToken");
    this.setState({
      ...this.state,
      isAuth: false,
    });
  };

  registerUser = async (user) => {
    // Sending the user registration request
    const register = await Axios.post("register.php", {
      name: user.name,
      email: user.email,
      password: user.password,
    });

    return register.data;
  };

  loginUser = async (user) => {
    // Sending the user Login request
    const login = await Axios.post("login.php", {
      email: user.email,
      password: user.password,
    });
    if (login.data.success) {
      console.log("logged in");
      Axios.defaults.headers.common["Authorization"] =
        "bearer " + login.data.token;

      // Fetching the user information
      const { data } = await Axios.get("user-info.php");

      // If user information is successfully received
      if (data.success && data.user) {
        console.log("logged in");
        this.setState({
          ...this.state,
          isAuth: true,
          theUser: data.user,
          token: login.data.token,
          authIsLoaded: true,
        });
      } else {
        this.setState({
          ...this.state,
          authIsLoaded: true,
        });
      }
    }
    return login.data;
  };

  // Checking user logged in or not
  isLoggedIn = async () => {
    const loginToken = localStorage.getItem("loginToken");
    console.log(loginToken);
    // If inside the local-storage has the JWT token
    if (loginToken) {
      //Adding JWT token to axios default header
      console.log("Adding auth token");
      Axios.defaults.headers.common["Authorization"] = "bearer " + loginToken;

      // Fetching the user information
      const { data } = await Axios.get("user-info.php");

      // If user information is successfully received
      if (data.success && data.user) {
        this.setState({
          ...this.state,
          isAuth: true,
          theUser: data.user,
          token: loginToken,
          authIsLoaded: true,
        });
      } else {
        this.setState({
          ...this.state,
          authIsLoaded: true,
        });
      }
    }
  };

  render() {
    const contextValue = {
      rootState: this.state,
      toggleNav: this.toggleNav,
      isLoggedIn: this.isLoggedIn,
      registerUser: this.registerUser,
      loginUser: this.loginUser,
      logoutUser: this.logoutUser,
    };
    return (
      <Context.Provider value={contextValue}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default ContextProvider;
