import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Divider from "@material-ui/core/Divider";
import neumann from "../assets/images/neumann.png";
import FA from "react-fontawesome";

const useStyles = makeStyles((theme) => ({
  frame: {
    marginBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
  },
  cardDetails: {
    width: "40%",
    marginTop: "auto",
    marginBottom: "auto",
  },
  cardContent: {
    margin: theme.spacing(3),
  },
  cardMedia: {
    maxheight: 570,
    width: "100%",
    padding: theme.spacing(5),
    display: "flex",
    flexFlow: "column",
  },
  cardImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    flex: "0 1 auto",
  },
  textContainer: {
    height: "100%",
    padding: theme.spacing(6),
    color: theme.palette.common.white,
    //background: "linear-gradient(180deg, rgba(183,219,123,1) 0%, rgba(118,185,0,1) 100%)",
    backgroundColor: theme.palette.background.main,
  },
  description: {
    marginTop: theme.spacing(3),
  },
}));

const contacts = [
  {
    img: neumann,
    title: "Prof. Dr.-Ing. Frank Neumann",
    description: (
      <div>
        Hochschule für Technik und Wirtschaft Berlin
        <br />
        Fachbereich 2 – Ingenieurswissenschaften
        <br />
        Wilhelminenhofstraße 75A
        <br />
        12459 Berlin
        <br />
        <FA name="envelope" />
        &nbsp;Frank.Neumann@HTW-Berlin.de
        <br />
        <FA name="phone" />
        &nbsp;+49 30 5019-3295
      </div>
    ),
    website: "https://www.htw-berlin.de/hochschule/personen/person/?eid=9685",
  },
];

export default function Contact() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  //States

  //Handlers

  return (
    <div className={classes.frame}>
      <Grid container spacing={3} justify="space-around">
        {contacts.map((contact, idx) => {
          const { img, title, description, website } = contact;
          return (
            <Grid key={"contact-" + idx} item md={3} style={{ width: "100%" }}>
              <Card className={classes.card}>
                <CardActionArea href={website}>
                  <div className={classes.cardMedia}>
                    <div style={{ flex: "1 1 auto" }}></div>
                    <img
                      className={classes.cardImage}
                      src={img}
                      alt="Kessler Solutions"
                    />
                    <div style={{ flex: "1 1 auto" }}></div>
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      className={classes.title}
                      component="h2"
                      variant="h5"
                    >
                      {title}
                    </Typography>
                    <Divider />
                    <Typography
                      className={classes.description}
                      component="div"
                      variant="subtitle1"
                    >
                      {description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
