import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Editor from "./Editor";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {},
}));

export default function AdminDialog(props) {
  const classes = useStyles();
  const { open, context, setClose, item, callback } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = React.useState({ ...item });

  React.useEffect(() => {
    setData(item);
  }, [item]);

  function handleChange({ key, val }) {
    setData({ ...data, [key]: val });
  }

  function handleClose() {
    setClose();
  }

  function handleSubmit() {
    callback({ item: data });
    setClose();
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">
        {context.toUpperCase()}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          {context !== "delete" && (
            <Editor item={data} callback={handleChange} />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          abbrechen
        </Button>
        <Button onClick={handleSubmit} color="primary" type="submit" autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
