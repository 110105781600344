import React, {useContext} from "react";
import {Context} from "../Context";
import {makeStyles} from "@material-ui/core/styles";
import SpinnerDialog from "../layout/SpinnerDialog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import SignIn from "./SignIn";
import Account from "./Account";
import Register from "./Register";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Footer from "./Footer";
import NavLink from "./NavLink";
import FA from "react-fontawesome";
import {Button} from "@material-ui/core";
import {useSnackbar} from "notistack";
import logo from "../assets/images/spa_logo.png";
import {Routes, Route, Link, useLocation} from "react-router-dom";
import Home from "./Home";
import News from "./News";
import Article from "./Article";
import Partners from "./Partners";
import Impressum from "./Impressum";
import Contact from "./Contact";

const useStyles = makeStyles((theme) => ({
    margin: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        },
    },
    container: {
        background: theme.palette.background.secondary,
        minHeight: "100vh",
        display: "flex",
        flexFlow: "column",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            boxShadow: theme.shadows[3],
        },
    },
    bannerLogo: {
        height: "2.5rem",
        verticalAlign: "middle"
    },
    banner: {
        lineHeight: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    nav: {
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.third,
        boxShadow: theme.shadows[2],
        ...theme.typography.primary,
    },
    toolbar: {
        padding: theme.spacing(2),
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    appbar: {
        flex: "0 1 auto",
    },
    tabpanel: {
        flex: "0 1 auto",
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flex: "1 1 auto",
        height: "100%",
    },
    content: {
        marginBottom: theme.spacing(6),
        /*[theme.breakpoints.down("md")]: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },*/
    },
    card: {
        display: "flex",
        height: "455",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.main,
    },
    cardDetails: {
        width: "40%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    cardMedia: {
        width: "60%",
        height: "684px",
    },
    grow: {
        flexGrow: 1,
    },
}));

export default function Frame() {
    let location = useLocation();
    const admin = location.pathname.search("/admin") > -1;
    const classes = useStyles();
    const {t} = useTranslation("common");
    const {enqueueSnackbar} = useSnackbar();
    const context = useContext(Context);
    const {rootState, logoutUser, loginUser, isLoggedIn} = context;
    const {isAuth, theUser, showLogin, authIsLoaded} = rootState;
    const {name} = theUser ? theUser : "";

    React.useEffect(() => {
        if (isAuth) {
            enqueueSnackbar(name, {variant: "success"});
        }
    }, [rootState]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const [isAccountDialogOpen, setAccountDialogOpen] = React.useState(false);
    const [isRegDialogOpen, setRegDiaglogOpen] = React.useState(false);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openAccountDialog = () => {
        setAccountDialogOpen(true);
    };

    const openRegDialog = () => {
        setRegDiaglogOpen(true);
    };

    const closeAccountDialog = () => {
        setAccountDialogOpen(false);
    };

    const closeRegDialog = () => {
        setRegDiaglogOpen(false);
    };

    const handleSignOut = () => {
        logoutUser();
        //navigate("/");
        enqueueSnackbar("abgemeldet", {variant: "success"});
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>{name}</MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <Button onClick={handleSignOut}>abmelden</Button>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <Button onClick={openAccountDialog}>Account</Button>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <Button onClick={openRegDialog}>Nutzer hinzufügen</Button>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.margin}>
            <Container disableGutters className={classes.container} maxWidth="xl">
                <Paper elevation={0} className={classes.banner}>
                    <Typography align="center">
                        <img src={logo} className={classes.bannerLogo}/>
                        <Typography color="secondary" variant="subtitle1" component="span">
                            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;ein Forschungsprojekt
                            unterstützt durch die Förderinitiative KMU-innovativ
                            „Mensch-Technik-Interaktion“ des BMBF
                        </Typography>
                    </Typography>
                </Paper>
                <AppBar position="sticky" variant="outlined" className={classes.nav}>
                    <Toolbar className={classes.toolbar}>
                        <NavLink to="/" label={t("home")}/>
                        <NavLink to="/news" label={t("news")}/>
                        <NavLink to="/partners" label={t("partners")}/>
                        <NavLink to="/contact" label={t("contact")}/>
                        <div className={classes.grow}/>
                        {admin && (
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleProfileMenuOpen}
                            >
                                <FA name="user"/>
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/admin" element={<Home admin={true}/>}/>
                        <Route path="/news" element={<News/>}/>
                        <Route exact={true} path="/news/admin" element={<News admin={true}/>}/>
                        <Route path="/news/:id" element={<Article/>}/>
                        <Route path="/partners" element={<Partners/>} />
                        <Route path="/contact" element={<Contact/>} />
                        <Route path="/impressum" element={<Impressum/>} />
                    </Routes>
                </div>
                <Footer className={classes.footer}/>
                <SignIn open={admin && !isAuth}/>
                <Account open={isAccountDialogOpen} close={closeAccountDialog}/>
                <Register open={isRegDialogOpen} close={closeRegDialog}/>
                {isAuth && renderMenu}
                {admin && !authIsLoaded && <SpinnerDialog/>}
            </Container>
        </div>

    );
}
