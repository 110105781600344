import React from "react";
import moment from "moment-timezone";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {Divider, Grid} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();


const useStyles = makeStyles((theme) => ({
    frame: {},
    metabanner: {
        textAlign: "center",
        color: theme.palette.text.hint,
        fontSize: theme.typography.subtitle1.fontSize,
        backgroundColor: theme.palette.grey[0],
        boxShadow: theme.shadows[0],
    },
    article: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[3],
    },
}));

export default function Article() {
    const classes = useStyles();
    const {t} = useTranslation("common");
    let params = useParams();
    const {id} = params;
    console.log("ARTICEL ID:", id);

    const initItem = () => {
        axios
            .post("https://sparepartassist.f2.htw-berlin.de/blogposts.php", {
                id: id,
            })
            .then((res) => {
                setItem(res.data.length ? res.data[0] : "");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [item, setItem] = React.useState("");
    React.useEffect(() => initItem(), []);

    return (
        <div className={classes.frame}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.article}>
                        <Typography gutterBottom align="right">
                            {moment(parseInt(item.date)).format("DD.MM.YYYY")}
                            ,&nbsp;
                            {item.author}
                        </Typography>
                        <Divider/>
                        {htmlToReactParser.parse(item.html)}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
