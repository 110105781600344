import React, { Component } from "react";
import ContextProvider from "./Context";
import logo from "./logo.svg";
import "./App.css";
import {
  withStyles,
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Frame from "./components/Frame";
import { SnackbarProvider } from "notistack";

const htw = createMuiTheme({
  palette: {
    background: {
      default: "#124369",
      secondary: "#f1f4f3",
      third: "#124369", //124369
      main: "#83c97e",
    },
  },
  typography: {
      primary: {
        fontFamily: "Squada One",
      },
      secondary: {
        fontFamily: "Open Sans",
      },
      fontFamily: "Open Sans",
  },
});

function App() {
  return (
    <ThemeProvider theme={htw}>
      <CssBaseline>
        <SnackbarProvider maxSnack={3}>
          <ContextProvider>
            <Frame />
          </ContextProvider>
        </SnackbarProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
